@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap);
/* -----------------------------------*/
/* ---------->>> GLOBAL <<<-----------*/
/* -----------------------------------*/
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html,
body {
  height: 100%;
}

.row {
  display: -webkit-flex;
  display: flex;
  width: 50%;
}

.column {
  -webkit-flex: 50% 1;
          flex: 50% 1;
}
::selection {
  background: blue;
  color: white;
}
#root{
  height:100%;
}


/* ---------------------------------------*/
/* ---------->>> Formulário <<<-----------*/
/* ---------------------------------------*/
.area-form {
  position: relative;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: linear-gradient(0deg,#0f1b35,#3662c2);
  padding: 15px 0 50px 0;
}

.content-area {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  z-index: 99;
  color: white;
}

.header-area {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.header-area img {
  width: 100%;
  max-width: 200px;
  height: auto;
}

.content-area h1,
.content-area p {
  text-align: center;
  margin: 25px 0;
}

.content-area h1 {
  font-size: 3em;
  font-weight: 600;
  color: #fff;
}

.content-area p {
  width: 50%;
  text-align: center;
  font-size: 22px;
}

.content-area form {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.content-area form input {
  width: 30%;
  min-width: 319px;
  padding: 15px;
  margin: 10px 0;
  border-radius: 20px;
  border: none;
}

#btn-form {
  position: relative;
  cursor: pointer;
  font-weight: 900;
  font-size: large;
  color: #00477F;
  transition: .4s;
  width: 250px;
  min-width: 74px;
  padding: 15px;
  margin: 10px 0;
  border-radius: 20px;
  border: none;
  outline: none;
}

#btn-form:hover {
  color: #00477F;
  background-color: #96EAD4;
  box-shadow: 0px 0px 30px #00477F;
}

.spinner-load{
  position: absolute;
  top: 7px;
  left: 20px;
  border: solid 8px rgba(0, 0, 0, .1);
  border-left-color: #008fff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  -webkit-animation: spinner 1s linear infinite;
          animation: spinner 1s linear infinite;
}

@-webkit-keyframes spinner {
  to{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner {
  to{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.content-area form input:focus {
  outline: none;
}

.content-area form input::-webkit-input-placeholder {
  color: #00477F;
  font-size: 15px;
}

.content-area form input:-moz-placeholder {
  color: #00477F;
  font-size: 15px;
}

/* Firefox 18- */
.content-area form input::-moz-placeholder {
  color: #00477F;
  font-size: 15px;
}

/* Firefox 19+ */
.content-area form input:-ms-input-placeholder {
  color: #00477F;
  font-size: 15px;
}

/* -------------------------------------*/
/* ---------->>> Rastreio <<<-----------*/
/* -------------------------------------*/

.content-area-track {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* justify-content: center; */
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  z-index: 99;
  color: white;
  padding-top: 40px;
}

.content-area-track img {
  width: 100%;
  max-width: 402px;
  height: auto;
  margin-bottom: 40px;
}

.content-area-track h5{
  font-weight: 300;
}
.content-area-track h1 {
  font-size: 30px;
  font-weight: 400;
  margin: 10px 0;
  text-align: center;
}

.content-area-track h1:nth-child(3) {
  font-size: 25px;
}

/* -------------------------------------------------*/
/* ---------->>> Timeline Staus Order <<<-----------*/
/* -------------------------------------------------*/
.hh-grayBox {
  margin-bottom: 20px;
  padding: 35px;
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  width: 70%;
}

.order-tracking {
  text-align: center;
  width: 33.33%;
  position: relative;
  display: block;
}

.order-tracking .is-complete {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 50px;
  width: 30px;
  border: 0px solid #AFAFAF;
  /* background-color: #fff; */
  margin: -20px auto 0px auto;
  /* margin: 0 auto; */
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
  color: white;
}

.order-tracking.completed .is-complete {

  background: transparent;
}

.order-tracking.completed .is-complete {
  color: #00FCF4;
  /* background-color: #00FCF4; */
}

.order-tracking.completed .is-complete:after {
  border-color: #fff;
  border-width: 0px 3px 3px 0;
  width: 7px;
  left: 11px;
  opacity: 1;
}

.order-tracking p {
  color: #fff;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 20px;
}

.order-tracking p span {
  font-size: 14px;
}

.order-tracking.completed p {
  color: #011f51;
}

.order-tracking::before {
  content: '';
  display: block;
  height: 3px;
  width: calc(100% - 50px);
  background-color: #fff;
  top: 13px;
  position: absolute;
  left: calc(-50% + 27px);
  z-index: 0;
}

.order-tracking:first-child:before {
  display: none;
}

.order-tracking.completed:before {
  background-color: #00FCF4;
}

/* -------------------------------------*/
/* ---------->>> ROW INFO <<<-----------*/
/* -------------------------------------*/
.title-status .column:nth-child(3){
  display: none;
}
.status{
  border-left: solid 2px #0cc4f1;
  /* border-left-width: thick; */
  margin: 10px 0;
}
.left-col {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}

.right-col {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}

.left-col span:nth-child(1) {
  color: #00FCF4;
}

/* -------------------------------------------------*/
/* ---------->>> Background Animation <<<-----------*/
/* -------------------------------------------------*/
.area {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to top, #4e54c8, #008fff);
  width: 100%;
  height: 100%;
  z-index: -8;


}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-animation: animate 25s linear infinite;
          animation: animate 25s linear infinite;
  bottom: -150px;

}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}


.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 12s;
          animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
  -webkit-animation-duration: 45s;
          animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 35s;
          animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 11s;
          animation-duration: 11s;
}



@-webkit-keyframes animate {

  0% {
      -webkit-transform: translateY(0) rotate(0deg);
              transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100% {
      -webkit-transform: translateY(-1000px) rotate(720deg);
              transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}



@keyframes animate {

  0% {
      -webkit-transform: translateY(0) rotate(0deg);
              transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100% {
      -webkit-transform: translateY(-1000px) rotate(720deg);
              transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}

@media only screen and (max-width: 500px) {
  .content-area h1 {
      font-size: 2.2em;
  }

  .content-area p {
      font-size: 15px;
      width: 80%;
  }

  .content-area h1,
  .content-area p {
      text-align: center;
      margin: 5px 0;
  }
}

@media only screen and (max-width: 900px) {
  .hh-grayBox {
      margin-top: 50px;
      padding: 0;
      width: 100%;
  }
  .order-tracking p {
      font-size: 12px;
  }
  .order-tracking p span{
      font-size: 9px;
  }

  .title-status .column:nth-child(1),
  .title-status .column:nth-child(2){
      display: none;
  }
  .title-status .column:nth-child(3){
      display: block;
      text-align: center;
  }
  .row{
      width: 90%;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
  }
  .status::before{
      content: "";
      height: 70px;
      border: solid 2px #00fcf4;
      margin: 0 auto;
  }
  .status{
      border-left: none;
  }
  .column{
      -webkit-flex: 100% 1;
              flex: 100% 1;
      margin-top: 20px;
  }
}

.error-msg {
    color: #D8000C;
    background-color: #FFBABA;
    width: 30%;
    min-width: 319px;
    padding: 10px 15px;
    border-radius: 5px;
  }
.list-order {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* border: solid 3px #; */
    margin: 0 20%;
    -webkit-flex-direction: column;
            flex-direction: column;
    z-index: 10;
}

.single-order {
    display: -webkit-flex;
    display: flex;
    border: solid 2px #011f51;
    border-radius: 10px;
    margin: 5px 0;
    padding: 5px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.single-order .column:nth-child(2) {
    -webkit-flex: 10% 1;
            flex: 10% 1;
}

.status-info .row {
    width: 100%;
}

.status-info .left-info {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 25% 1;
            flex: 25% 1;
    /* border: solid 2px yellow; */
}

.status-info .left-info img {
    /* height: 100px; */
    width: 100%;
    max-width: 200px;
}

.status-info .right-info {
    -webkit-flex: 75% 1;
            flex: 75% 1;
    margin-left: 10px;
    /* border: solid 2px green; */
}

.status-info .right-info h4 {
    color: #fff;
    font-weight: 500;
    margin-bottom: 15px;
}

.status-info .right-info span {
    width: 100%;
    font-size: 12px;
    color: #fff;
    /* font-weight: 500; */
}
.status-info h3{
    margin-left: 8px ;
}



.icon-status {
    margin-left: 20px;
}

.more-info {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 10px;
    border-left: solid 2px #fff;
    text-align: right;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.more-info .btn {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    font-weight: 600;
    background-color: #fff;
    font-size: 18px;
    color: #00477F;
    transition: .4s;
    padding: 5px 10px;
    border: none;
    outline:none;
    /* width: 200px; */
    /* min-width: 74px; */
    border-radius: 20px;
    margin: 10px 0;
}
.btn-load {
    height: 100%;
    width: 100%;
    font-size: small;
}
.more-info .btn .spinner-load{
    position: relative;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
}

.more-info .btn a {
    font-weight: 600;
    font-size: 15px;
    color: #000;
    text-decoration: none;
}

.more-info .btn:hover {
    color: #fff;
    background-color: #00DCFF;
    box-shadow: 0px 0px 30px #00477F;
}

.links-redirect a{
    color: #011f51;
    text-decoration: none;
    text-align: left;
    transition: .6s;
}
.links-redirect a:hover{
    color: white;
}

.error-message {
    background: white;
    color: red;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    border-radius: 15px;
    padding: 15px 10px;
}
.cd-track {
    color: #0f1b35;
    letter-spacing: 5px;
    font-weight: 900;
}

.spinner-load-track {
    border: solid 20px rgb(15 28 54);
    border-left-color: #008fff;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    -webkit-animation: spinner 1s linear infinite;
            animation: spinner 1s linear infinite;
    margin: 150px auto;
}

.error-track {
    height: 100%;
    z-index: 99;
    color: white;
    text-align: left;
    margin: 0 auto;
    padding: 50px 30px;
}

.error-track h1 {
    font-size: 2.5em;
}

.error-track p {
    margin-top: 1rem;
}

.error-track p span {
    text-decoration: underline;
    color: #222d45;
    font-size: 1.2em;
    background-color: #fff;
}

.error-track a {
    text-decoration: none;
    color: black;
    transition: .3s;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
}

.error-track a:hover {
    color: white;
    background-color: black;
}
.copyright{
    text-align: center;
    color: #fff;
    font-size: 12px;
    margin-top: 20px;
    width: 100%;
    position: absolute;
    bottom: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
