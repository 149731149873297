.cd-track {
    color: #0f1b35;
    letter-spacing: 5px;
    font-weight: 900;
}

.spinner-load-track {
    border: solid 20px rgb(15 28 54);
    border-left-color: #008fff;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    animation: spinner 1s linear infinite;
    margin: 150px auto;
}

.error-track {
    height: 100%;
    z-index: 99;
    color: white;
    text-align: left;
    margin: 0 auto;
    padding: 50px 30px;
}

.error-track h1 {
    font-size: 2.5em;
}

.error-track p {
    margin-top: 1rem;
}

.error-track p span {
    text-decoration: underline;
    color: #222d45;
    font-size: 1.2em;
    background-color: #fff;
}

.error-track a {
    text-decoration: none;
    color: black;
    transition: .3s;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
}

.error-track a:hover {
    color: white;
    background-color: black;
}