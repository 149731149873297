.list-order {
    display: flex;
    justify-content: center;
    /* border: solid 3px #; */
    margin: 0 20%;
    flex-direction: column;
    z-index: 10;
}

.single-order {
    display: flex;
    border: solid 2px #011f51;
    border-radius: 10px;
    margin: 5px 0;
    padding: 5px;
    flex-wrap: wrap;
}

.single-order .column:nth-child(2) {
    flex: 10%;
}

.status-info .row {
    width: 100%;
}

.status-info .left-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 25%;
    /* border: solid 2px yellow; */
}

.status-info .left-info img {
    /* height: 100px; */
    width: 100%;
    max-width: 200px;
}

.status-info .right-info {
    flex: 75%;
    margin-left: 10px;
    /* border: solid 2px green; */
}

.status-info .right-info h4 {
    color: #fff;
    font-weight: 500;
    margin-bottom: 15px;
}

.status-info .right-info span {
    width: 100%;
    font-size: 12px;
    color: #fff;
    /* font-weight: 500; */
}
.status-info h3{
    margin-left: 8px ;
}



.icon-status {
    margin-left: 20px;
}

.more-info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    border-left: solid 2px #fff;
    text-align: right;
    flex-direction: column;
}

.more-info .btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    background-color: #fff;
    font-size: 18px;
    color: #00477F;
    transition: .4s;
    padding: 5px 10px;
    border: none;
    outline:none;
    /* width: 200px; */
    /* min-width: 74px; */
    border-radius: 20px;
    margin: 10px 0;
}
.btn-load {
    height: 100%;
    width: 100%;
    font-size: small;
}
.more-info .btn .spinner-load{
    position: relative;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
}

.more-info .btn a {
    font-weight: 600;
    font-size: 15px;
    color: #000;
    text-decoration: none;
}

.more-info .btn:hover {
    color: #fff;
    background-color: #00DCFF;
    box-shadow: 0px 0px 30px #00477F;
}

.links-redirect a{
    color: #011f51;
    text-decoration: none;
    text-align: left;
    transition: .6s;
}
.links-redirect a:hover{
    color: white;
}

.error-message {
    background: white;
    color: red;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    border-radius: 15px;
    padding: 15px 10px;
}