.copyright{
    text-align: center;
    color: #fff;
    font-size: 12px;
    margin-top: 20px;
    width: 100%;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}